import React from "react";
import NavBar from "../Component/Navs/navbar";
import Team from "../Component/Team/Team";
import Erik from "../pic/erik-926.webp";
import Steven from "../pic/Steven.webp";
import Islam from "../pic/Islam-Elnashar82.webp";
import David from "../pic/David.webp";
import Mohamed from "../pic/Mohyamedf.webp";

const ManagementTeam = ({ title }) => {
  const data = [
    {
      img: Erik,
      name: "Erik Vollebregt ",
      position: "Executive Chairman",
      details:
        "Erik Vollebregt is a seasoned Oil and Gas executive and energy technology Venture Capital Investment manager with over 30 years of experience in the global energy industry and over 10 years in a $1bln (Corporate) Venture Capital Activity. Mr. Vollebregt held management roles in Royal Dutch Shell in increasingly senior positions including Project Manager Billiton Metals Divestment, GM Shell companies in Zaire (Democratic Republic of Congo)., Group Chief Internal Auditor and Commercial and Finance Director of Shell Companies in Nigeria, one of Shell’s largest and most challenging operations where o.a. he was responsible for the turnaround and growth agenda and launched the $8.5 BLN integrated Upstream/LNG expansion project. As the co-founder and manager of the >$1bln STV Fund energy technology fund 1 he and his team introduced many novel upstream technologies to the industry and a number of which were subsequently acquired by leading integrated services companies Schlumberger, Halliburton, Weatherford and Baker Hughes.",
    },
    {
      img: Steven,
      name: "Steven van Rossem ",
      position: "Managing & Technical Director",
      details:
        "Steven van Rossem has joined Scimitar Production Egypt Ltd. June 2019. as Technical Director and subsequently was also appointed as the CEO of the company. Mr. van Rossem holds a Msc degree in Geology/Sedimentology, from Utrecht University in the Netherlands and has a career spanning more than 30 years in the upstream oil and gas industry. He worked for Shell in the Netherlands, England, Scotland, Kazakhstan and Oman in various E&P technical and managerial positions as well as for DNO as General Manager in Oman. In his various roles, he covered conventional and nonconventional oil fields. During his career his focus has been on strong integration of multidisciplinary teams across the entire organization as the key success to nonconventional oil field development and production.",
    },
    {
      img: Islam,
      name: "Dr. Islam El Nashar",
      position: "Country Manager & COO",
      details:
        "Dr. Islam El-Nashar has over 35 years of Oil and Gas Operations and Projects experience, where he occupied different roles in multinational companies lately as General Manager and Managing Director of Gulf of Suez Petroleum Company representing BP and Dragon oil respectively before rejoining Scimitar. During his career Dr. Islam has worked in Egypt, North Sea, Milan, Houston, Abu Dhabi and Azerbaijan. The first ten years of his career he worked in the front line as production supervisor, Operations Team leader and Offshore Installation Manager where he learned that integration between functions is the key element of any development and success of an organization. During the period from 2006 till 2008 Dr. Islam played a significant role in Scimitar as Vice President Operations and Engineering. He believes strongly that operating discipline is the key for safe reliable and cost-efficient operations.",
    },
    {
      img: David,
      name: "David van Erp",
      position: "Finance & Commercial Director",
      details:
        "David van Erp joined Scimitar as Commercial and subsequently as Financial & Commercial Director in February 2019. Mr. Van Erp brings with him 15 years of Oil & Gas industry experience, predominantly through Investment Banking roles with Macquarie/ Tristone and GMP FirstEnergy where he fulfilled numerous roles in both Corporate Finance and Equity research targeting the small and mid-cap E&P sector. Prior to joining Scimitar, Mr. van Erp served as Vice President, Corporate Finance at GMP FirstEnergy in London where he was involved in various oil and gas Financings, M&A, A&D and Strategic Alternatives processes. At the start of his career, he was an Offshore Engineer at Intec Engineering/Heerema Group. Mr. Van Erp holds a M.Sc. in Hydrocarbon Enterprise (Distinction) from the Department of Petroleum Geology at the University of Aberdeen and a B.Eng. in Technology & Management from the University of Professional Education of Amsterdam.",
    },
    {
      img: Mohamed,
      name: "Mohamed Heshmat Hussein ",
      position: "HR & Administration Director",
      details:
        "Mohamed Heshmat Hussein joined Scimitar as Human Resources & Administration Director in July 2021, bringing over 34 years of diverse Human Resources Management experience to our team. In this position, he oversees all aspects of human resources & Administrations management for more than 500 employees. Prior to joining Scimitar, he occupied the positions of HR director in several leading Multinational Organizations. Amongst them Asec Cement, Sipetrol Oil Company, AlkanCIT Group and Smash Management covering several sectors, such as Petroleum, pharmaceutical, construction, sports, hospitality and Information Technology. Mohamed has held a number of other positions in the field of human resources, including HR Manager in 1990 with Conoco Exploration, Egypt,  Repsol Exploracion Egipto and Regional HR Director for Abbott Laboratory in Egypt, North Africa & Levants, HR Manager for Repsol In Spain 2005, Regional HR Manager for Repsol in the Gulf. Throughout his career he has lived and worked in Europe, Africa, Asia, the Middle East and Egypt, where he managed to develop an excellent understanding and experience of different cultures coupled with fluency in several Languages, very strong Communication, Leadership, People Management Skills, Strategic Thinking, problem solving and the ability to develop and retain relations at all levels. Mohamed is strong promoter of continuous development and training of our people.",
    },
  ];
  return (
    <>
      <NavBar />
      <h1 style={{ textAlign: "center" }}>{title}</h1>
      <Team data={data} />
    </>
  );
};

export default ManagementTeam;

import React, { forwardRef, useEffect } from "react";
import classes from "./Card.module.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Card = forwardRef((props, ref) => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div className={`${classes.card}`} ref={ref}>
        <div className={`${classes.center}`}>
          <h2
            data-aos="zoom-in"
            data-aos-delay="700"
            data-aos-duration="800"
            className={`${classes.h2h}`}
            style={{ color: "#cb9903" }}
          >
            Welcome To
          </h2>
          <h1
            data-aos="fade-right"
            data-aos-delay="800"
            className={`${classes.h1h}`}
          >
            Scimitar
            <span
              data-aos="fade-left"
              data-aos-delay="900"
              style={{
                color: "#cb9903",
                marginLeft: "2rem",
              }}
            >
              Egypt
            </span>
          </h1>
          <p
            data-aos="zoom-in"
            data-aos-delay="1000"
            className={`${classes.ps}`}
            style={{ fontSize: "2rem", color: "#FFFFFF" }}
          >
            Scimitar Production Egypt Ltd. is an independent private oil
            producing company <br></br> at the forefront of heavy oil production
            in Egypt.
          </p>
        </div>
      </div>
    </>
  );
});

export default Card;

import React from "react";
import "./Numcounter.css";
import Stack from "@mui/material/Stack";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { Grid } from "@mui/material";

const Numcounter = () => {
  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        className="cont"
      >
        <h1
          style={{
            fontSize: "5.5rem",
            color: "#FFFFFF",
            letterSpacing: "3.5px",
          }}
        >
          Important <span style={{ color: "#cb9903" }}>Statistics :</span>
        </h1>
        <Stack
          justifyContent="center"
          direction={{ xs: "column", sm: "row" }}
          spacing={10}
          paddingBottom={2}
        >
          <div>
            <Stack spacing={1}>
              <h2 className="text">
                <CountUp end={700} suffix="+">
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start}>
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
              </h2>
              <h3 className="headtitle">
                Million Stock Tank Barrel oil in place
              </h3>
            </Stack>
          </div>
          <div>
            <Stack spacing={1}>
              <h2 className="text">
                <CountUp end={3500}>
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start}>
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
              </h2>
              <h3 className="headtitle">Barrel Oil Per Day (BOPD)</h3>
            </Stack>
          </div>
          <div>
            <Stack spacing={1}>
              <h2 className="text">
                <CountUp end={30000}>
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start}>
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
              </h2>
              <h3 className="headtitle">Barrel Steam Injection Capacity</h3>
            </Stack>
          </div>
          <div>
            <Stack spacing={1}>
              <h2 className="text">
                <CountUp end={180} suffix="+">
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start}>
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
              </h2>
              <h3 className="headtitle">Producing Wells</h3>
            </Stack>
          </div>
        </Stack>
      </Grid>
    </>
  );
};

export default Numcounter;

import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import social1 from "../../../pic/٢٠٢٢٠٤١٥_١٠٣٤٣٨.webp";
import social2 from "../../../pic/15.webp";
import social3 from "../../../pic/777302c9-b7b8-44f4-90b9-b168fd0836ca.webp";
import social4 from "../../../pic/IMG_20221021_090748_097.webp";
import social5 from "../../../pic/IMG-20220606-WA0002.webp";
import social6 from "../../../pic/ddb94972-f938-4d65-a693-dd8bd198a168.webp";
import social7 from "../../../pic/IMG-20221024-WA0015.webp";
import social8 from "../../../pic/ak2.webp";
import social9 from "../../../pic/12.webp";
import social10 from "../../../pic/00001.webp";
import social11 from "../../../pic/IMG_1736.webp";
import social12 from "../../../pic/IMG-20211202-WA0002.webp";
import social13 from "../../../pic/last1.webp";
import social14 from "../../../pic/shaimaa.webp";
import social15 from "../../../pic/last3.webp";
import social16 from "../../../pic/1 WhatsApp Image 2023-08-01 at 5.16.36 PM.webp";
import social17 from "../../../pic/2 34.webp";
import social18 from "../../../pic/3 53.webp";

const itemData = [
  {
    img: social1,
    title: "Scimitar Pepole",
  },
  {
    img: social3,
    title: "Scimitar Pepole",
  },
  {
    img: social2,
    title: "Scimitar Pepole",
  },
  {
    img: social18,
    title: "Scimitar Pepole",
  },

  {
    img: social7,
    title: "Scimitar Pepole",
  },
  {
    img: social8,
    title: "Scimitar Pepole",
  },
  {
    img: social5,
    title: "Scimitar Pepole",
  },

  {
    img: social9,
    title: "Scimitar Pepole",
  },

  {
    img: social12,
    title: "Scimitar Pepole",
  },
  {
    img: social14,
    title: "Scimitar Pepole",
  },
  {
    img: social13,
    title: "Scimitar Pepole",
  },

  {
    img: social10,
    title: "Scimitar Pepole",
  },
  {
    img: social11,
    title: "Scimitar Pepole",
  },
  {
    img: social17,
    title: "Scimitar Pepole",
  },

  {
    img: social15,
    title: "Scimitar Pepole",
  },
  {
    img: social6,
    title: "Scimitar Pepole",
  },
  {
    img: social4,
    title: "Scimitar Pepole",
  },
  {
    img: social16,
    title: "Scimitar Pepole",
  },
];
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "max",
  outline: "none",
};
const Imagelist = () => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box>
      <ImageList
        sx={{ paddingLeft: { xs: "0", sm: "74px" } }}
        cols={3}
        rowHeight={165}
      >
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <img
              src={open}
              width="100%"
              style={{ alignContent: "center" }}
            ></img>
          </Box>
        </Modal>
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              key={Math.random()}
              src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
              alt={item.title}
              loading="lazy"
              onClick={() => setOpen(item.img)}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
};

export default Imagelist;

import React from "react";
import "./Team.css";

import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";

export default function Model3({ data = [], num }) {
  const [open, setOpen] = React.useState(() =>
    new Array(data.length).fill(false)
  );
  const handleOpen = (i) =>
    setOpen((prev) =>
      prev.map((op, idx) => {
        if (idx !== i) return op;
        return true;
      })
    );
  const handleClose = (i) =>
    setOpen((prev) =>
      prev.map((op, idx) => {
        if (idx !== i) return op;
        return false;
      })
    );

  return (
    <>
      <div id="model3">
        {data.map((card, i) => {
          return (
            <React.Fragment key={i}>
              <div

                onClick={() => handleOpen(i)}
                style={num ? { flex: "0 0 20%" } : null}
                className="member"
              >
                <img width={200} height={200} src={card.img} />

                <div className="description">
                  <h1 style={{ fontWeight: "lighter", fontSize: "28px" }}>
                    {card.name}{" "}
                  </h1>
                  <h2 style={{ fontWeight: "lighter", fontSize: "18px" }}>
                    {card.position}{" "}
                  </h2>
                </div>
              </div>
              <Dialog
                scroll="paper"
                open={open[i]}
                onClose={() => handleClose(i)}
              >
                <Box>
                  <img
                    className="img"
                    width={200}
                    height={200}
                    src={card.img}
                  />
                  <p style={{ fontSize: "1.2rem", padding: "1rem" }}>
                    {card.details}
                  </p>
                </Box>
              </Dialog>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
}

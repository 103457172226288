import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import HSSE from "./Pages/HSSE";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ManagementTeam from "./Pages/ManagementTeam";
import SMTTeam from "./Pages/SMTTeam";
import Issaran from "./Pages/Issaran";
import FieldInfrastructure from "./Pages/FieldInfrastructure";
import GPC from "./Pages/GPC";
import VISION from "./Pages/VISION";
import Social from "./Pages/Social";
import Contactus from "./Pages/Contactus";
import { ScrollRestoration } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

const theme = createTheme({
  typography: {
    fontFamily: ["Hind", "sans-serif"].join(","),
  },
});

const router = createBrowserRouter([
  {
    path: "/managementteam",
    element: <><ScrollRestoration /> <ManagementTeam /></>,
  },
  {
    path: "/smttteam",
    element: <><ScrollRestoration /> <SMTTeam /></>,
  },
  {
    path: "/issaran",
    element: <><ScrollRestoration /> <Issaran /></>,
  },
  {
    path: "/fieldinfrastructure",
    element: <><ScrollRestoration /> <FieldInfrastructure /></>,
  },
  {
    path: "/social",
    element: <><ScrollRestoration /> <Social /></>,
  },
  {
    path: "/vision",
    element: <><ScrollRestoration /> <VISION /></>,
  },
  {
    path: "/gpc",
    element: <><ScrollRestoration /> <GPC /></>,
  },
  {
    path: "/",
    element: <><ScrollRestoration /> <Home /></>,
  },
  {
    path: "/about",
    element: <><ScrollRestoration /> <About /></>,
  },
  {
    path: "/HSSE",
    element: <><ScrollRestoration /> <HSSE /></>,
  },
  {
    path: "/contactus",
    element: <><ScrollRestoration /> <Contactus /></>,
  },
]);

root.render(
  <>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </>
);

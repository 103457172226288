import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import logo from "../../pic/Logo-Final11.webp";
import { Link } from "react-router-dom";

export default function Nav() {
  return (
    <Box sx={{ flexGrow: 1 }} style={{ marginTop: 80, marginBottom: 80 }}>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={12} sm={6} sx={{ textAlign: "center" }}>
          <Link to={"/"}>
            <img width="300rem" alt="logo" src={logo} />
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ textAlign: "center" }}>
          <Link to={"/contactus"}>
            <Button
              style={{ backgroundColor: "#cb9903", fontSize: "15px" }}
              variant="contained"
            >
              Contact Us
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}

import React from "react";
import NavBar from "../Component/Navs/navbar";
import TwoSecton from "../Component/2Sections/2Sections";
import GPCc from "../pic/GPC.webp";

const GPC = () => {
  const data = [
    {
      img: GPCc,
      h1: "GPC",
      p: [
        "Scimitar operates the Issaran field under a Petroleum Service Agreement with the General Petroleum Company (“GPC”) a state-owned company established in 1957. Under this agreement the company took control of financing exploration, development and production of the field.",
        "GPC and Scimitar have a strong and effective working relationship covering technical operations, well delivery, HSSE, inventory checks and secondment of GPC engineers at Scimitar. The unique relationship with GPC has been built over many years involving many individuals of both companies.",
        "GPC and Scimitar are together aiming to maximize the recovery of the large Issaran Field, through field development that is cost effective and innovative and is using state of the art technology.",
        "you can learn more about GPC and their operations by visiting GPC’s website:",
      ],
      width: "800px",
      height: "700px",
      buttonn: "Learn more",
    },
  ];
  return (
    <>
      <NavBar />
      <TwoSecton data={data} />
    </>
  );
};

export default GPC;

import * as React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import "./cardmidhead.css";

export default function Cardmidhead() {
  return (
    <Paper
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "dark" ? "#1A2027" : "#dbdbdb",
        backgroundImage: 'url("../../pic/about.jpg")',
        backgroundSize: "cover",
      }}
      className="paper"
    >
      <Grid
        container
        spacing={{ xs: 8, md: 15 }}
        justifyContent="center"
        className="mainGrid"
      >
        <Grid item xs={6}>
          <h2 className="h21">
            <span
              style={{
                textDecoration: "underline",
                textDecorationColor: "#cb9903",
                textUnderlineOffset: "15px",
              }}
            >
              Scimitar
            </span>{" "}
            Egypt Story
          </h2>
        </Grid>
        <Grid item xs container direction="column" spacing={2}>
          <Grid item xs>
            <p className="p1">
              Scimitar Production Egypt Ltd. is a private oil producing company
              at the forefront of thermal Enhanced Oil Recovery (EOR)
            </p>
            <Button
              sx={{
                justifyContent: "left",
                display: "flex",
                backgroundColor: "#cb9903",
              }}
              className="button1"
              variant="contained"
            >
              <Link to={"/about"}> Learn More </Link>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";

import "./parallax.css";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

const Parallexs = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <Grid container sx={{ display: "flex" }} spacing={0}>
        <Grid xs={12} md={6}>
          <div className="effect parallax  ">
            <Typography
              variant="h1"
              sx={{ fontSize: "32px", color: "#373737", fontWeight: "bold" }}
            >
              Field Development
            </Typography>
            <p style={{ fontSize: "18px" }}>
              Production from the Issaran field depends mainly on thermal
              recovery from heavy oil-bearing shallow formations at 1000'-2000'
              depth.
            </p>
          </div>
        </Grid>
        <Grid xs={12} md={6}>
          <div className="effect parallax1"></div>
        </Grid>
        <Grid xs={12} md={6}>
          <div className="effect parallax2"></div>
        </Grid>
        <Grid xs={12} md={6}>
          <div className="effect parallax3">
            <Typography
              align="left"
              sx={{
                fontSize: "32px",
                color: "#cb9903",
                paddingLeft: "5%",
                paddingTop: "18%",
                paddingBottom: "3%",
                fontWeight: "bold",
              }}
            >
              GPC
            </Typography>
            <Typography
              align="left"
              sx={{
                paddingLeft: "5%",
                fontSize: "18px",
                paddingBottom: "2%",
                maxWidth: "500px",
              }}
            >
              Scimitar operates the Issaran field under a Petroleum Service
              Agreement (PSA) with the General Petroleum Company (“GPC”) who is
              the owner of the concession.
            </Typography>
            <Button
              sx={{
                marginTop: "15px",
                color: "#cb9903",
                marginLeft: "5%",
                fontSize: "15px",
              }}
            >
              <Link to={"/gpc"}> Learn More </Link>
            </Button>
          </div>
        </Grid>
        <Grid xs={12} md={6} style={{}}>
          <div className="effect parallax4">
            <Typography
              sx={{
                fontSize: "32px",
                color: "#373737",
                paddingLeft: "18px",
                fontWeight: "bold",
              }}
              className="header"
              align="left"
            >
              Company Overview
            </Typography>
            <Typography
              align="left"
              sx={{
                paddingX: "2.5rem",
                paddingY: "1.5rem",
                fontSize: "18px",
              }}
              className="parrlex"
            >
              Scimitar Production Egypt Ltd. is an independent private oil
              producing company at the forefront of heavy oil production. The
              company is operating in Egypt with activities that range from
              exploration, development, drilling operations, oil production,
              well intervention, steam generation and injection and the support
              and general services for responsible and safe operations.
              <br></br>
              <br></br>The company operates through its office in Cairo, and the
              field operations base on the shore of the Gulf of Suez. The
              company is working as an integrated team through continuous
              production optimization, learning and applying new technologies.
            </Typography>
          </div>
        </Grid>
        <Grid xs={12} md={6}>
          <div className="effect parallax5"></div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Parallexs;

import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const TwoSecton = ({ data = [] }) => {
  return (
    <>
      {data.map((card, i) => {
        return (
          <Box key={i}
          >
            <Grid container>
              <Grid xs={12} md={6} sx={{ overflow: "hidden" }}>
                <img
                  height={card.height}
                  width={"100%"}
                  alt=""
                  src={card.img}
                />
              </Grid>

              <Grid
                xs={12}
                md={6}
                sx={{
                  paddingLeft: "2%",
                  paddingRight: "2%",
                }}
              >
                <h1 style={{ paddingTop: "10%", fontSize: "30px" }}>
                  {card.h1}
                </h1>
                {card.p.map((singleP) => (
                  <p
                    key={Math.random()}
                    style={{
                      fontSize: "18px",
                    }}
                  >
                    {singleP}
                  </p>
                ))}
                <h1 style={{ fontSize: "30px" }}>{card.h11}</h1>
                <ul>
                  {card.li?.map((li) => (
                    <li key={Math.random()} style={{ fontSize: "18px" }}>
                      {li}
                    </li>
                  ))}
                </ul>
                <div>
                  {" "}
                  {card.buttonn && (
                    <Button
                      href="http://www.gpc.com.eg"
                      size="large"
                      sx={{ fontSize: "2rem" }}
                      variant="text"
                      target="_blank"
                    >
                      {card.buttonn}
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          </Box>
        );
      })}
    </>
  );
};

export default TwoSecton;

import React from "react";
import { Link } from "react-router-dom";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Nav from "./navbar1";

import "./navbar.css";

function NavBar() {
  return (
    <>
      <Nav />
      <AppBar
        position="sticky"
        component="nav"
        sx={{ backgroundColor: "#000000" }}
      >
        <Toolbar
          sx={{ justifyContent: "center", flexWrap: "wrap" }}
          className="navbar"
        >
          <div className="dropdown">
            <Link to={"/"} className="hover-underline-animation">
              Home
            </Link>
          </div>
          <div className="dropdown">
            <Link className="hover-underline-animation" to={""}>
              {" "}
              About
            </Link>
            <div className="dropdown-content">
              <Link to={"/about"}>Scimitar Egypt Story</Link>

              <div className="dropdown w-100">
                <a>
                  Leadership <ArrowRightRoundedIcon sx={{ float: "right" }} />
                </a>
                <div className="dropdown-content right">
                  <Link to={"/managementteam"}> Management Team</Link>
                  <Link to={"/smttteam"}> SMT Team</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="dropdown">
            <Link to={""} className="hover-underline-animation">
              Operations
            </Link>
            <div className="dropdown-content">
              <Link to={"/issaran"}>Issaran Field </Link>
              <Link to={"/fieldinfrastructure"}>Field Infrastructure</Link>
              <a
                target="_blank"
                href="https://onepetro.org/search-results?q=Issaran%20field&sort=Relevancy&fl_SiteID=1&page=1&qb={%22q%22:%22Issaran%20field%22}"
              >
                Heavy Oil
              </a>{" "}
              <Link to={"/gpc"}>GPC</Link>
            </div>
          </div>
          <div className="dropdown">
            <Link className="hover-underline-animation" to={"/vision"}>
              Vision & Mission
            </Link>
          </div>
          <div className="dropdown">
            <Link className="hover-underline-animation" to={""}>
              Sustainability
            </Link>
            <div className="dropdown-content">
              <Link style={{ fontSize: "15px" }} to={"/HSSE"}>
                HSE
              </Link>{" "}
              <Link
                style={{ fontSize: "15px" }}
                className="hover-underline-animation"
                to={"/social"}
              >
                Sustainable Development & Social Responsibilities
              </Link>
            </div>
          </div>
          <div className="dropdown">
            <Link to={""} className="hover-underline-animation">
              Career
            </Link>

            <div className="dropdown-content">
              <a href="mailto:info@scimitaregypt.com">Email</a>

              <a
                target="_blank"
                href="https://eg.linkedin.com/company/scimitar-production-egypt-ltd-spel"
              >
                Linkedin
              </a>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default NavBar;

import React from "react";
import NavBar from "../Component/Navs/navbar";
import HalfHalf from "../Component/Halfhalf/halfhalf";
const About = () => {
  return (
    <>
      <NavBar />
      <HalfHalf />
    </>
  );
};

export default About;

import React from "react";
import NavBar from "../Component/Navs/navbar";
import Container from "@mui/material/Container";
import Blog from "../Component/Blog/Blog";
import Grid from "@mui/material/Unstable_Grid2";
import hc1 from "../pic/Hosptial-1-1024x471.webp";
import hc2 from "../pic/Hosptital-2-1024x471.webp";
import hc3 from "../pic/Hosptial-3-1024x471.webp";
import edu1 from "../pic/school-1-1024x768.webp";
import edu2 from "../pic/school-2-1024x768.webp";
import edu3 from "../pic/school-3-1024x768.webp";
import edu4 from "../pic/school4.webp";
import edu5 from "../pic/school5.webp";
import edu6 from "../pic/school6.webp";
import edu7 from "../pic/school7.webp";
import edu8 from "../pic/school8.webp";
import edu9 from "../pic/school9.webp";
import social4 from "../pic/social5.webp";
import social5 from "../pic/social6.webp";

import social1 from "../pic/social1.webp";
import social2 from "../pic/social2.webp";
import social3 from "../pic/social3.webp";
import hc4 from "../pic/social4.webp";

const SocialContributions = [
  {
    label: "Social Contribution",
    imgPath: social4,
  },
  {
    label: "Social Contribution",
    imgPath: social1,
  },

  {
    label: "Social Contribution",
    imgPath: social2,
  },
  {
    label: "Social Contribution",
    imgPath: social3,
  },
  {
    label: "Social Contribution",
    imgPath: social5,
  },
];
const HealthCare = [
  {
    label: "Health Care",
    imgPath: hc1,
  },

  {
    label: "Health Care",
    imgPath: hc2,
  },
  {
    label: "Health Care",
    imgPath: hc3,
  },
  {
    label: "Health Care",
    imgPath: hc4,
  },
];

const Education = [
  {
    label: "Education",
    imgPath: edu9,
  },
  {
    label: "Education",
    imgPath: edu1,
  },
  {
    label: "Education",
    imgPath: edu8,
  },
  {
    label: "Education",
    imgPath: edu4,
  },
  {
    label: "Education",
    imgPath: edu6,
  },
  {
    label: "Education",
    imgPath: edu5,
  },
  {
    label: "Education",
    imgPath: edu7,
  },
  {
    label: "Education",
    imgPath: edu2,
  },
  {
    label: "Education",
    imgPath: edu3,
  },
];
const Social = () => {
  return (
    <>
      <NavBar />
      <Container fixed style={{ color: "#383838" }}>
        <h1 style={{ fontSize: "32px", fontWeight: "800px" }}>
          Sustainable Development & Social Responsibilities
        </h1>
        <p style={{ fontSize: "18px" }}>
          Scimitar  contributes to the sustainable development of its
          surrounding communities in three major sectors:{" "}
          <span
            style={{ fontStyle: "italic", color: "#72772", fontWeight: "bold" }}
          >
            Education, Healthcare and Decent Way of Life,
          </span>{" "}
          which is aligned with the President Directions and the government
          efforts to uplift these three sectors.
        </p>
        <p style={{ fontSize: "18px" }}>
          Scimitar also supports the local municipality in their efforts in
          dealing with road accidents, road repair, trees planting, natural
          crises, flooding, pollution reduction and medical care in Ras Gharib
          and Zaafrana.
        </p>
        <p style={{ fontSize: "18px" }}>
          Scimitar’s Contribution in Ras Gharib includes In-kind donation to
          schools, hospitals, clinics, and poor communities in close
          coordination with local authorities.
        </p>
        <p style={{ fontSize: "18px" }}>
          Scimitar supports job seekers in Ras Gharib and provides training and
          internships to university students. Many of our contractors and
          suppliers are from Ras Gharib. This is another way in which Scimitar’s
          presence supports the local Ras Gharib economy and helps it to nourish
          and grow.
        </p>
      </Container>
      <Grid container spacing={2} sx={{ marginX: "10%" }}>
        <Grid xs={12} md={4}>
          <Blog data={HealthCare} />
        </Grid>
        <Grid xs={12} md={4}>
          <Blog data={Education} />
        </Grid>
        <Grid xs={12} md={4}>
          <Blog data={SocialContributions} />
        </Grid>
      </Grid>
    </>
  );
};

export default Social;

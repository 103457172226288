import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";

import "./halfhalf.css";
import Imagelist from "./Imagelist/Imagelist";

export default function HalfHalf() {
  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={0}>
        <Grid xs={12} md={6}>
          <div
            className="aboutt"
            style={{ height: "550px ", width: "100%" }}
          ></div>
        </Grid>
        <Grid xs={12} md={6}>
          <Container fixed sx={{ paddingLeft: { md: "7rem", xs: 2 } }}>
            <h1 style={{ paddingTop: "25px", fontSize: "20px" }}>
              Scimitar Production Egypt Story
            </h1>
            <p style={{ fontSize: "18px" }}>
              Scimitar Production Egypt Ltd. is a private oil Exploration and
              Production (E&P) company at the forefront of thermal Enhanced Oil
              Recovery (EOR) in Egypt. Since its establishment in 1998, it has
              built a robust reputation in the production and development of
              heavy oil from the Issaran fractured carbonate field through a
              specialized organization and EOR technology deployment.
            </p>
            <p style={{ fontSize: "18px" }}>
              The Issaran field is one of the few fields in the world producing
              heavy oil from fractured carbonate reservoirs and is the first
              heavy oil development in Northern Africa stimulated with steam.
              The field has been producing since October 2001. Over the last
              several years a number of new technologies have been brought to
              bear in different reservoir sections of the field.
            </p>
            <p style={{ fontSize: "18px" }}>
              Scimitar Operates under a 1998 Production Services Agreement (PSA)
              with GPC, which has recently been updated to stimulate further
              field development.
            </p>
          </Container>
        </Grid>
        <Grid style={{ backgroundColor: "#f2f2f2" }} xs={12} md={6}>
          <div style={{ textAlign: "left", paddingTop: "10%" }}>
            <Container fixed>
              <h1 style={{ fontSize: "20px" }}>Scimitar People</h1>
              <p style={{ fontSize: "18px", maxWidth: "650px" }}>
                Our integrated Team of 500 people, sustain Scimitar's business
                performance from our main office in Cairo and our operations
                base in the Issaran field. Scimitar’s team is fundamental to
                develop the unconventional Issaran oil field competitively by
                working safely together across Scimitar, combined with
                continuous learning and applying new technologies.
              </p>
            </Container>
          </div>
        </Grid>
        <Grid xs={12} md={6}>
          <Imagelist />
        </Grid>
      </Grid>
    </Box>
  );
}

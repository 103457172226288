import React from "react";
import NavBar from "../Component/Navs/navbar";
import "./contactus.css";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Typography } from "@mui/material";

const Contactus = () => {
  return (
    <>
      <NavBar />
      <div className="card">
        <Typography
          variant="h3"
          sx={{ color: "white", paddingLeft: "2%", paddingTop: "5%" }}
        >
          Contact Us{" "}
        </Typography>
      </div>
      <Box sx={{ color: "#393939" }}>
        <Grid
          container
          sx={{ paddingBlock: "5rem" }}
          alignContent={"center"}
          alignItems="start"
          justifyContent={"center"}
          textAlign={"center"}
        >
          <Grid xs={3}>
            <PhoneIcon
              sx={{ fontSize: "60px", paddingBottom: "30px" }}
            ></PhoneIcon>
            <Typography
              sx={{
                fontWeight: "600px",
                fontSize: "26px",
                paddingBottom: "30px",
              }}
            >
              Phone
            </Typography>
            <Typography>+20 (02) 2247-0832</Typography>
          </Grid>
          <Grid xs={3}>
            <EmailIcon
              sx={{ fontSize: "60px", paddingBottom: "30px" }}
            ></EmailIcon>
            <Typography
              sx={{
                fontWeight: "600px",
                fontSize: "26px",
                paddingBottom: "30px",
              }}
            >
              Email
            </Typography>
            <a href="mailto:info@scimitaregypt.com">
              <Typography> info@scimitaregypt.com </Typography>
            </a>
          </Grid>
          <Grid xs={3}>
            <LocationOnIcon
              sx={{ fontSize: "60px", paddingBottom: "30px" }}
            ></LocationOnIcon>
            <Typography
              sx={{
                fontWeight: "600px",
                fontSize: "26px",
                paddingBottom: "30px",
              }}
            >
              Location
            </Typography>
            <Typography>
              {" "}
              <a href="https://www.google.com/maps?q=30.07066535949707,31.451038360595703&z=17&hl=en">
                Block-E, plot # 5, 1st Settlement Service Center , New Cairo
                11477, Cairo, Egypt.
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Contactus;

import React from "react";
import NavBar from "../Component/Navs/navbar";
import TwoSecton from "../Component/2Sections/2Sections";
import mission from "../pic/mission.webp";

const VISION = () => {
  const data = [
    {
      img: mission,
      h1: "VISION",
      h11: "MISSION",
      p: [
        "In Scimitar We are aiming to attain industry best practice recovery factors for the field in a responsible and economic manner by applying specialized EOR knowledge and technology and utilizing the full potential of our people by providing a motivating, learning and safe working environment.",
      ],
      li: [
        "Explore and develop oil and gas in a sustainable manner to the benefit of all stakeholders.",
        "Produce the many parts of the Issaran field in the most profitable and responsible manner.",
        "Run all aspects of the company efficiently and in a compliant manner.",
        "Be the partner of choice for GPC and develop lasting relationships with local contractors.",
        "Use the full potential of our people and work constructively with our local communities.",
        "Balance and reconcile the needs of all our stakeholders.",
      ],
      width: "800px",
      height: " 756.35px",
    },
  ];
  return (
    <>
      <NavBar />
      <TwoSecton data={data} />
    </>
  );
};

export default VISION;

import React from "react";
import NavBar from "../Component/Navs/navbar";
import Team from "../Component/Team/Team";
import MohamedSad from "../pic/MohamedSad.webp";
import Emad from "../pic/Emad Nabil.webp";
import MohamedAbdallah from "../pic/MohamedAbdallah.webp";
import AbdulMajid from "../pic/AbdulMajid.webp";
import Wael from "../pic/Wael.webp";
import Shabn from "../pic/Shaban.webp";
import Mostafa from "../pic/Mostafa.webp";
const SMTTeam = () => {
  const data = [
    {
      img: MohamedAbdallah,
      name: " Mohamed Abdallah",
      position: "Deputy Technical Director",
      details:
        "Mohamed Abdallah has joined Scimitar as Planning Manager in February 2020 overseeing the Planning, Scheduling and new technology functions necessary to achieve the successful and timely execution of a portfolio of drilling campaigns and projects to ensure effective operations. In January 2022, Mohamed was promoted to Deputy Technical Director of the company. Upon obtaining his bachelor’s degree in petroleum engineering from Cairo University in 2007, Mohamed began his career at Sahara Oil & Gas, as a Reservoir engineer in 2008, where he acquired significant experience in a broad range of engineering disciplines. He was promoted to Senior Petroleum Engineer & Acting Sr. Petroleum Economist in 2012. Mohamed also holds a master’s degree in petroleum engineering from Cairo University. His thesis is titled “Reservoir Characterization of A/R G Formation for Enhanced Oil Recovery Implementation”. Furthermore, Mohamed is currently enrolled in the executive Management Diploma at the American University in Cairo (AUC).",
    },
    {
      img: Emad,
      name: " Emad Nabil",
      position: "Well Engineering Manager",
      details:
        "Emad Nabil After obtaining his bachelor degree in petroleum engineering from Al Azhar University in 2001, He commenced his professional career by joining Gempetco company, the joint venture of Pico, as a petroleum engineer. He worked as a consultant with Apache before starting his journey in Scimitar as workover engineer in 2007. During his 15 years of experience in Scimitar, Emad held a variety of senior roles in the drilling and workover fields until he was promoted to the role of Workover Manager in 2015 and following that Well Engineering Manager in November 2021. Overseeing the drilling and workover operations of hundreds of wells, and the intervention of scheduled and unscheduled deferments workover operations in many wells in Issaran field. Emad has a reliable reputation for his leadership and technical skills.",
    },

    {
      img: MohamedSad,
      name: " Mohamed Saad",
      position: "Production Optimization Manager",
      details:
        "Mohamed Saad is a Production Optimization manager with more than 16 years of diversified international experience. He holds a B.Sc. degree in Petroleum Engineering from Cairo University, faculty of Engineering, Egypt, and a Diploma degree in production operation from SAIT institute, Canada. The main focus of Mohamed’s experience is production engineering. He is an expert in most production engineering subspecialties and has participated and directed numerous Field Development Plans and Full Field Integrated studies. Mohamed’s career started in early 2006 when he joined Agiba Company as a production engineer and then he joined scimitar in 2007 as field production engineer. Mohamed has taken the accountabilities of all petroleum production operations activities. In early 2013, He was appointed as Sr. production engineer holding the production technology and stimulation projects for one of the most challenge fields of heavy oil production. Mohamed has deep experience in planning, controlling and managing the activities of various operational functions focused on artificial lift systems, acidizing portfolio and production Optimization.",
    },

    {
      img: AbdulMajid,
      name: "Abdul-Majid Houri",
      position: "QHSSE Manager",
      details:
        "Abdul-Majid Houri has thirty years of practical experience in Health, Safety, Security & Environment (HSSE) working with International Oil Companies in the Oil & gas industry. He has joined Scimitar Production Egypt Ltd. as QHSSE Manager in January 2020 and is responsible for managing the company’s QHSSE management systems and related activities. Prior to joining Scimitar, he occupied the position of Business & HSE Support Manager at BGP International Egypt. In 1988, he joined Syria Shell Petroleum Development. Abdul-Majid’s journey into HSSE developed over several work assignments in the Middle East & North Africa. In Egypt, Abdul-Majid worked as the HSSE Manager for Shell Egypt N.V (2013-2016). He holds a master’s degree in business administration and the British Safety Diploma. He is a member in several HSSE entities; Board member with the Syrian Occupational Health Association (NGO), Member of Road Accident Prevention (NGO) and Euphrates River Oil Spill Contingency plan. He is the recipient of several awards & certificates for outstanding HSE achievements such as the Best Safety Seismic Acquisition Project in MENA awarded by EYGPS in 2019 and the Shell Safety award for the Safe Seismic acquisition activities in 2015.",
    },

    {
      img: Wael,
      name: "Wael Azzazy",
      position: "Engineering Manager",
      details:
        "Wael Azzazy brings 30 years of experience in the industry to the table. He has a track record with Scimitar Production Egypt Ltd. Since 2007 as Maintenance Manager and subsequently as Field General Manager. He has a bachelor’s degree in engineering from Cairo University. Throughout his career, he has demonstrated a strong ability to lead teams, manage complex projects, and drive results. His expertise in engineering, project management, and operations has been honed through a variety of roles and responsibilities, including overseeing the design and implementation of large-scale upstream projects, managing budgets and schedules, and improving operational efficiencies. His leadership style is focused on empowering teams to reach their full potential while also fostering a culture of collaboration and innovation.",
    },
    {
      img: Shabn,
      name: "Sha'ban Hussaini",
      position: "Field General Manager ",
      details:
        "Sha’ban Hussaini possesses 15 years of experience in the heavy oil EOR industry.  He Started his career in 2008 as production engineer at GUPCO after obtaining his B.Sc, degree in petroleum engineer in 2007 from Suez Canal University. Later in 2008 he joined Scimitar as workover supervisor and pursued his career in Scimitar through different technical and supervisory roles. Sha’ban proficiency and leadership skills paved the way towards promoting him to be Deputy Field Manager in 2021 and Field General Manager in the following year, overseeing the Issaran heavy oil field activities, integration between different functions and managing a total of 450 employees.",
    },
    {
      img: Mostafa,
      name: "Mostafa Abdel Aal",
      position: "Field General Manager",
      details:
        "Mostafa Abdel Aal started his 17 years of experience in heavy oil field production operations in Issaran oil Field by joining Scimitar in 2007, following obtaining his bachelor’s degree in petroleum engineering from the Suez Canal University in 2006. Along his career path in Scimitar, Mostafa was assigned to different technical and supervisory roles, where he worked, participated, and supervised in activities contributed to the development process of Issaran field, proving efficiency and leadership. Mostafa joined the Scimitar management team in 2021 as Deputy Field Manager, and was promoted to Field General Manager in 2022, overseeing the Issaran heavy oil field activities, integration between different functions and managing a total of 450 employees. Mostafa believes that the person’s experience shouldn’t be calculated by years, but by the achievements and success steps made and contributed to those years.",
    },
  ];
  return (
    <>
      <NavBar />
      <h1 style={{ marginLeft: "38%", fontSize: "65px" }}>SMT Team</h1>
      <Team data={data} num="true" />
    </>
  );
};

export default SMTTeam;

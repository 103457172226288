import React from "react";
import NavBar from "../Component/Navs/navbar";
import TwoSecton from "../Component/2Sections/2Sections";
import field from "../pic/filed.webp";
const FieldInfrastructure = () => {
  const data = [
    {
      img: field,
      h1: "Field Infrastructure",
      p: [
        "Production of Issaran depends mainly on thermal recovery from heavy oil-bearing shallow formations at 1000’-2000’ depth. At Issaran, steam is injected into the reservoir to reduce the viscosity of the oil to enhance its mobility. Steam generators, with around 30k barrel per day of steam capacity are located at different strategic locations in the field. A steam network delivers steam to most of the wells in the Issaran field. The steam injection mechanisms include cyclic steam (CSS), Group steaming (GCSS) and Continuous Steam Injection (CSI). The production of the Issaran field in both 2022 and 2023 peaked at around 4000-barrel oil per day produced from its 180 producing wells. Most of the production comes from the southern part of the field as well as the central (block G) area which saw intensive development drilling in 2021 and 2022.",
        "The Issaran field facilities were significantly upgraded in 2010 to support future development plans. This resulted in a significant improvement of facilities integrity and of the available field capacity. Significant improvement was also achieved in safety and efficiency of operations in several areas. Today, the production handling capacity is more than 20,000-barrel fluids (oil & water) per day.",
      ],
      // li: [
      //   "	4 terminals for custody transfer to GPC, 2 in service and 2 spares.",
      //   "	13 Steamers with some 30,000 barrel of steam per day total capacity.",
      //   "	Natural Gas Pressure Reduction Station (PRS) of 20k m3/hr.       ",
      //   "	A diesel fuel station consisting of 3 tanks of 200k liter total capacity.        ",
      //   "	Power Station consisting of 4 Sets of natural gas generators of 2.7 MW total capacity.  ",
      //   "	Fire tender and ambulance.        ",
      //   "	Heavy equipment yard.        ",
      // ],
      height: "650px",
      width: "800px",
    },
  ];
  return (
    <>
      <NavBar />
      <TwoSecton data={data} />
    </>
  );
};

export default FieldInfrastructure;

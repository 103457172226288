import React from "react";
import NavBar from "../Component/Navs/navbar";
import hsse1 from "../pic/hsse1.webp";
import hsse2 from "../pic/hsse2.webp";
import hsse3 from "../pic/hsse3.webp";
import hsse4 from "../pic/hsse4.webp";
import hsse5 from "../pic/hsse5.webp";
import hsse6 from "../pic/DSC_0536.webp";
import Blog from "../Component/Blog/Blog";
import Grid from "@mui/material/Unstable_Grid2";

const SocialContributions = [
  {
    label: "Firefighting Team",
    imgPath: hsse1,
  },

  {
    label: "Lining up of Scimitar’s crisis response equipment demonstration.",
    imgPath: hsse2,
  },
  {
    label:
      "Advanced Firefighting training at the firefighting training center in Suez City",
    imgPath: hsse3,
  },
  {
    label: "Commissioning of Issaran field Firefighting system",
    imgPath: hsse4,
  },
  {
    label: "Contractors’ Safety Workshop in Issaran field",
    imgPath: hsse5,
  },
  {
    label: "Scimitar’s safety contest",
    imgPath: hsse6,
  },
];
const History = () => {
  return (
    <>
      <NavBar />
      <div style={{ paddingLeft: "15%", paddingRight: "15%" }}>
        <h1 style={{ fontSize: "32px", fontWeight: "800px" }}>HSE</h1>
        <p
          style={{
            marginTop: "2%",
            fontSize: "18px",
          }}
        >
          Scimitar developed a Health, Safety & Environment (HSE) management
          system for managing the operations safely and efficiently. We are
          committed to pursuing{" "}
          <span style={{ fontWeight: "bold" }}>
            The Goal Zero of{" "}
            <span
              style={{
                textDecoration: "underline",
                textDecorationColor: "#cb9903",
                textDecorationThickness: "2px",
                textUnderlineOffset: "5px",
              }}
            >
              NO HARM
            </span>{" "}
          </span>
          to people and protect the environment.
        </p>
        <p
          style={{
            marginTop: "2%",
            fontSize: "18px",
          }}
        >
          Scimitar takes personal and process safety as our first priority. It
          is considered a crucial pillar in our operations. We have introduced
          the best reasonable practical practices and procedures. We work
          closely with our contractors on an efficient and safe working
          environment for all our employees as well as the contractors’
          employees.
        </p>
        <p
          style={{
            marginTop: "2%",
            fontSize: "18px",
          }}
        >
          Scimitar adopts the Oil & Gas Life-Saving Rules to reduce the risk of
          fatal accident and serious injuries significantly if followed
          correctly. The Life Saving Rules are mandatory and communicated to all
          staff & contractors in a very simple and clear message. Non-compliance
          with the Life Saving Rules will not be tolerated by Scimitar.
        </p>
        <p
          style={{
            marginTop: "2%",
            fontSize: "18px",
          }}
        >
          Process safety is adopted for safe operations around our surface
          facilities, wells, well construction and well re-entry. We apply a
          continuous improvement program by identifying and updating all
          associated risks within our operations. The bowtie methodology is used
          to set control barriers and to identify recovery measures to minimize
          the enduring and point risks to ALARP (As low As Reasonably
          Practical).
        </p>

        <p
          style={{
            marginTop: "2%",
            fontSize: "18px",
          }}
        >
          Personal and Process safety are crucial factors to keep our operations
          safe and to achieve a working environment free from incidents and
          injuries.
        </p>
      </div>
      <Grid container justifyContent={"center"}>
        <Grid xs={10} sm={4}>
          <Blog data={SocialContributions} />
        </Grid>
      </Grid>
    </>
  );
};

export default History;

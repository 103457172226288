import React, { useEffect, useRef } from "react";
import NavBar from "../Component/Navs/navbar";
import Card from "../Component/Cards/Card";
import Header3cards from "../Component/Header3cards/header3cards";
import Cardmidhead from "../Component/MidDivCard/cardmidhead";
import Parallexs from "../Component/Parallax/parallax";
import Numcounter from "../Component/CounterUp/Numcounter";

const Home = () => {
  const cardRef = useRef();
  useEffect(() => {
    cardRef.current.scrollIntoView();
  }, []);
  return (
    <>
      <NavBar />
      <Card ref={cardRef} />
      <Header3cards />
      <Cardmidhead />
      <Parallexs />
      <Numcounter />
    </>
  );
};

export default Home;

import React from "react";
import NavBar from "../Component/Navs/navbar";
import TwoSecton from "../Component/2Sections/2Sections";
import issaran from "../pic/issaran.webp";

const Issaran = () => {
  const data = [
    {
      img: issaran,
      h1: "Issaran Field Operations Historical Track Record",
      p: [
        "Issaran’s main reservoirs are heavy oil shallow heterogeneous fractured carbonate reservoirs with complicated structural regimes. Issaran is located in the Eastern Dessert, 290 km southeast of Cairo inland from the western shore of the Gulf of Suez of Egypt. The Issaran field covers 72 km2 (20,000 acres).",
        "Issaran was discovered in 1981 by LL&E. After various appraisal wells by LL& E and ARCO the field was deemed to be sub-commercial, and the area relinquished. The General Petroleum Company (GPC), a state-owned company took over as operator in 1987 and produced relatively small amounts of oil by primary depletion and the cumulative production up to 1998 was less than one million barrels from 9 wells.",
        "The major heavy oil accumulations are present within 5 main shallow Miocene formations (Zeit Sand, Upper Dolomite, Lower Dolomite, Gharandal and Nukhul). The Zeit sand is a highly permeable sandstone reservoir, and the other four reservoirs are highly fractured carbonates. The average reservoir depth is 1200 ft, varying from 650 to 2100 ft. The oil is heavy at 12 ° API gravity, as viscous as 1,000-9,000cP (at 40deg. C), with a very low Gas Oil Ratio (GOR).",
        "From the mid-nineties, the Egyptian government made a concerted effort towards developing this complex field. In 1998 Scimitar started the heavy oil pilot project with GPC in Issaran by signing a Petroleum Service Agreement (PSA), under which the company took control of financing exploration, development and production of the field. At that time, the Stock Tank Oil Initially In Place (STOIIP) was estimated at around 400 Million barrels, of which the recovery factor was less than 1% and daily production was 170 barrel oil per day (bopd).",
        "During the period 2005-2008 a major development took place consisting of cold production and thermal production phases. Some 240 wells were drilled, facilities installed, and production increased to 8000bopd during 2007 mostly from the Nukhul reservoir before natural decline set in.",
        "Starting 2019 under the new management, Scimitar adopted a strategy to grow production from 2000bopd to over 5000bopd and unlock undeveloped parts of the field by following an integrated field development and optimization strategy and the application of variety of technologies.",
        "In 2023, the company successfully piloted a global first by combining short radial Perfobore drilling with acid stimulation and steam injection in heavy oil.",
      ],
      height: "1200px",
    },
  ];
  return (
    <>
      <NavBar />
      <TwoSecton data={data} />
    </>
  );
};

export default Issaran;

import React from "react";

import "./header3cards.css";

import Stack from "@mui/material/Stack";

const Header3cards = () => {
  return (
    <div>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        className="stack"
      >
        <div className="cardcontainer cardcontainer2 ">
          <h3 className="midd">Reliable Operations</h3>
          <p className="p2">99% Up Time</p>
        </div>

        <div className="cardcontainer cardcontainer3">
          <h3 style={{ color: "#CB9903" }} className="midd">
            Major Resources
          </h3>
          <p className="p2">
            In excess of 700 Million <br></br> Barrel oil in place (MMSTB){" "}
          </p>
        </div>

        <div className="cardcontainer cardcontainer1">
          <h3 className="midd">Workforce</h3>
          <p className="p2">Enhanced Oil Recovery Specialist </p>
        </div>
      </Stack>
    </div>
  );
};
export default Header3cards;
